import httpClient from "../httpClient"

import { formatData } from "../request"
import { encodeRequestParameters } from "../request"

import { centralUnitsByResidenceFormat, CentralUnitsFormat } from "../formats"

/**
 * Returns the central units of a residence
 */
export function getCentralUnitsByResidence(data) {
    data = formatData(centralUnitsByResidenceFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .get("/centrale", { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function printCentralUnitsByResidence(data) {
    data = formatData(CentralUnitsFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)

    const fileName = "centrales.xlsx"
    const apiEndpoint = "/centrales/export"
    const header = {
        Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }
    return httpClient
        .get(apiEndpoint, { params, header, responseType: "arraybuffer" })
        .then((response) => {
            const url = window.URL.createObjectURL(
                new Blob([response], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
            )
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", fileName)
            document.body.appendChild(link)
            link.click()
        })
        .catch((error) => console.log(error))
}
